import React from "react";
import Navbar from "../../Navbar/Navbar";
import AboutUsHeadCard from "./AboutUsHeadCard/AboutUsHeadCard";
import Tokenomics from "./Tokenomics Card/Tokenomics";
import Footer from "../../Footer Section/Footer";
import RoadMap from "./Road Map Card/RoadMap";

const AboutUsPage = () => {
  return (
    <div>
      <Navbar />
      <AboutUsHeadCard />
      <RoadMap />
      <Tokenomics />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
