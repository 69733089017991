import React from "react";
import Navbar from "../../Navbar/Navbar";
import ContactUs from "./Contact Us Card/ContactUs";
import Footer from "../../Footer Section/Footer";

const ContactPage = () => {
  return (
    <div>
      <Navbar />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default ContactPage;
