import React from "react";
import styles from "./AboutUsHeadCard.module.css";

const AboutUsHeadCard = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.aboutUsContent}>
        <h2>ABOUT US</h2>
        <p>
          Crypto Cricket is an innovative online platform that merges gaming and
          cryptocurrency trading, utilizing various crypto tokens, notably BALL,
          for participation. Focused on the sport of cricket, it offers users
          opportunities to engage in fantasy cricket leagues, make predictions,
          and earn rewards based on real-life match performances. The platform
          enhances user interaction with cricket through blockchain technology,
          offering a unique blend of entertainment and economic benefits in the
          digital space.
        </p>
        <div className={styles.knowMoreBtn}>
          {/* <button>KNOW MORE</button> */}
          <a
            // className={styles.knowMoreBtn}
            href="https://docs.balltokenverse.com/whitepaper"
            target="_blank"
          >
            KNOW MORE
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeadCard;
