import React from "react";
import styles from "./ContactUs.module.css";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";

const ContactUs = () => {
  return (
    <div className={styles.contactUsContainer}>
      <div className={styles.content}>
        <div className={styles.contactDetails}>
          <div className={styles.header}>
            <h6>CONTACT US</h6>
            <h2>
              COME BE A <span>PART OF TEAM </span> CRYPTO CRICKET
            </h2>
            <p>
              Give wings to your aspirations in Fantasy Cricket. Join us as a
              player today!
            </p>
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.addressItem}>
              <i>
                <FaLocationDot />
              </i>
              <div className={styles.info}>
                <h6>Our location</h6>
                <p>
                  Sixpep Technovations Pvt Ltd <br /> Plot no 194, Road no 38,
                  Ayyappa Society, Madhapur, <br /> Hyderabad - 500081
                </p>
              </div>
            </div>
            <div className={styles.addressItem}>
              <i>
                <FaPhone />
              </i>
              <div className={styles.info}>
                <h6>Phone Number</h6>
                <p>+91-6305602096</p>
              </div>
            </div>
            <div className={styles.addressItem}>
              <i>
                <HiOutlineMail />
              </i>
              <div className={styles.info}>
                <h6>Email Address: </h6>
                <p>support@balltokenverse.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contactForm}>
          <div className={styles.form}>
            <div className={styles.userNameWrap}>
              <input type="text" placeholder="Your Name" />
              <input type="email" placeholder="Email" />
            </div>
            <div className={styles.userEntryWrap}>
              <input type="number" placeholder="Phone" />
              <input type="text" placeholder="Game" />
            </div>
            <textarea name="" id="" placeholder="Message" rows={10} />
          </div>
          <div className={styles.sendBtn}>
            <button>Send Message</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
