import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Users from "../Home Page/Team and Users Card/Users Card/Users";
import ContentHeroSection from "./Contest Hero Card/ContentHeroSection";
import LeaderBoardTable from "./LeaderBoardTable/LeaderBoardTable";
import Footer from "../../Footer Section/Footer";
import { IP, PORT } from "../../../config";
import axios from "axios";

const LeaderBoardPage = () => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [rewards, setRewards] = useState([]);

  const getLeaderBoardData = async () => {
    try {
      const res = await axios.get(`${IP}:${PORT}/api/website-data/tournament`);
      console.log(res?.data);
      setLeaderBoardData(res?.data?.leaderboard);
      setRewards(res?.data?.rewards);
    } catch (error) {}
  };

  useEffect(() => {
    getLeaderBoardData();
  }, []);

  return (
    <div>
      <Navbar />
      <ContentHeroSection rewards={rewards} />
      <LeaderBoardTable leaderboardData={leaderBoardData} />
      <Users backgroundImage={"/assets/battleBg.png"} />
      <Footer />
    </div>
  );
};

export default LeaderBoardPage;
