import React, { useRef } from "react";
import styles from "./TournamentsCard.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IsDesktopSM } from "../../../../Constants";
import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";
import useIntersectionObserver from "../../../UseableComponents/useIntersectionObserver";

const TournamentsCard = () => {
  const [headRef, headerVisible] = useIntersectionObserver({ threshold: 1 });
  // const headRef = useRef();
  // const headerVisible = useIntersectionObserver(headRef, { threshold: 1 });

  const carouselItems = [
    {
      imgSrc: "/assets/predictions.png",
      title: "Prediction",
      text: "Predict various aspects of cricket matches and use your knowledge to increase your chances of winning.",
      link: "/prediction",
    },
    {
      imgSrc: "/assets/fantasy.png",
      title: "Fantasy",
      text: "Apply your cricket insights to make informed decisions and lead your playing XI to victory in Fantasy Cricket.",
      link: "/fantasy",
    },
    {
      imgSrc: "/assets/sessions.png",
      title: "Sessions",
      text: "Participate in real-time prediction sessions during live matches, offering single-question contests.",
      link: "/sessions",
    },
  ];

  return (
    <div className={styles.tournamentsWrap}>
      <div className={styles.content}>
        <div
          className={`${styles.header} ${headerVisible ? styles.animate : ""}`}
          ref={headRef}
        >
          <h2>
            {/* CRYPTO CRICKET <br /> */}
            <span>GAMES</span>
          </h2>
          <p>
            Put your cricket knowledge to work on our transparent platform,
            where winners are rewarded.
          </p>
        </div>
        <div className={styles.carousel}>
          {IsDesktopSM() ? (
            carouselItems.map((item, index) => {
              return (
                <div
                  className={styles.carouselItem}
                  key={index}
                  onClick={() => (window.location.pathname = item.link)}
                >
                  <div className={styles.itemLogo}>
                    <img src={item.imgSrc} alt="" />
                  </div>
                  <h6>{item.title}</h6>
                  <p>{item.text}</p>
                  <a href={item.link}>Read More</a>
                </div>
              );
            })
          ) : (
            <Carousel
              centerMode
              autoPlay={true}
              interval={2000}
              centerSlidePercentage={33}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={false}
              // renderArrowPrev={(clickHandler, hasPrev) => {
              //   return (
              //     <div className="customArrowNext" onClick={clickHandler}>
              //       <HiMiniArrowLongRight size={25} />
              //     </div>
              //   );
              // }}
              // renderArrowNext={(clickHandler, hasNext) => {
              //   return (
              //     <div className="customArrowPrev" onClick={clickHandler}>
              //       <HiMiniArrowLongLeft size={25} />
              //     </div>
              //   );
              // }}
            >
              {carouselItems?.map((item, index) => {
                return (
                  <div
                    className={styles.carouselItem}
                    key={index}
                    onClick={() => (window.location.pathname = item.link)}
                  >
                    <div className={styles.itemLogo}>
                      <img src={item.imgSrc} alt="" />
                    </div>
                    <h6>{item.title}</h6>
                    <p>{item.text}</p>
                    <a href={item.link}>Read More</a>
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default TournamentsCard;
