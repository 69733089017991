import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { VscSearch } from "react-icons/vsc";
import { RxCross2 } from "react-icons/rx";
import { FaCaretDown } from "react-icons/fa";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showDropdown, setShowDropDown] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navItems}>
        <div className={styles.logo}>
          <a href="/">
            <img src="/assets/ccLogo.png" alt="Crypto Cricket Logo" />
          </a>
        </div>
        {/* <div className={styles.panCake}>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=0x1294d35c494c5E3169df9b0Ffe03186Ba914effE&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
            target="_blank"
            rel="noreferrer"
          >
            Buy & Sell BALL Token on Pancakeswap
          </a>
        </div> */}
        <div className={`${styles.navMenu} ${menuOpen ? styles.open : ""}`}>
          <div className={styles.menuCloseBtn}>
            <RxCross2
              size={25}
              onClick={toggleMenu}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className={styles.menuItem}>
            <a href="/">HOME</a>
          </div>
          <div
            className={`${styles.menuItem} ${styles.tournamentNavItem}`}
            onClick={() => setShowDropDown(!showDropdown)}
          >
            <a>
              GAMES <FaCaretDown />
            </a>
            <div
              className={styles.tournamentDropdown}
              style={{
                visibility: showDropdown ? "visible" : "hidden",
                display: showDropdown ? "" : "none",
              }}
            >
              <a href="/fantasy">Fantasy</a>
              <a href="/prediction">Prediction</a>
              <a href="/sessions">Live Sessions</a>
            </div>
          </div>
          <div className={styles.menuItem}>
            <a href="/aboutus">ABOUT US</a>{" "}
          </div>
          {/* <div className={styles.menuItem}>
            <a href="/leaderboard">LEADERBOARD</a>
          </div>
          <div className={styles.menuItem}>
            <a href="/contact">CONTACT</a>
          </div> */}
          <div className={`${styles.menuItem} ${styles.playNowBtn}`}>
            <a href="https://s3.ap-south-2.amazonaws.com/production.cryptocricket/apks/cryptocricket.apk">
              PLAY NOW
            </a>
          </div>
        </div>
        <div className={styles.navRight}>
          {/* <div className={styles.search}>
            <VscSearch color="white" size={25} />
          </div> */}
          <div
            className={`${styles.hamburger} ${menuOpen ? styles.open : ""}`}
            onClick={toggleMenu}
          >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
