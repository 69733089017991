import React from "react";
import styles from "./BattleTeam.module.css";
import useIntersectionObserver from "../useIntersectionObserver";

const BattleTeam = ({ right, teamName, teamDetails, teamLogo }) => {
  const [matchCompRef, matchCompVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <div
      className={`${styles.battleTeamWrap} ${
        right ? styles.battleTeamWrapRight : ""
      } ${matchCompVisible ? styles.animate : ""}`}
      ref={matchCompRef}
    >
      <div
        className={
          right
            ? `${styles.titleWrap} ${styles.titleWrapRight}`
            : styles.titleWrap
        }
      >
        <div
          className={
            right ? `${styles.titleRight} ${styles.title}` : styles.title
          }
        >
          <h6>{teamName}</h6>
        </div>
        <div
          className={
            right
              ? `${styles.battleSubtitleRight} ${styles.battleSubtitle}`
              : styles.battleSubtitle
          }
        >
          <p>
            {isNaN(teamDetails) ? teamDetails : `Match Number: ${teamDetails}`}
          </p>
        </div>
      </div>
      <div
        className={
          right ? `${styles.teamLogo} ${styles.teamLogoRight}` : styles.teamLogo
        }
      >
        <img src={teamLogo} alt="Team Logo" />
      </div>
    </div>
  );
};

export default BattleTeam;
