import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.policyContainer}>
      <div className={styles.header}>
        <h1>Privacy Policy for Crypto Cricket</h1>
      </div>
      <div className={styles.rulesContainer}>
        <div className={styles.content}>
          <section>
            <p>
              <b>Last Updated</b>: 09/08/2024
            </p>
            <p>
              At Crypto Cricket, your privacy is of paramount importance to us.
              This Privacy Policy explains how we collect, use, and protect your
              personal information when you interact with our website and
              services.
            </p>
            <h2>1. Information We Collect</h2>

            <p>
              <strong>Email Addresses:</strong> We collect your email address
              when you voluntarily subscribe to our newsletter through a form on
              our website.
            </p>

            <p>
              <strong>Usage Data:</strong> We use Google Analytics to collect
              information about your interaction with our website. This may
              include data such as your IP address, browser type, device
              information, pages visited, and the time spent on those pages.
              Google Analytics may also use cookies to track your activity on
              our site.
            </p>
          </section>
          <section>
            <h2>2. How We Use Your Information</h2>
            <p>
              <strong>Email Communication:</strong> The email address you
              provide is used solely to send you newsletters, announcements,
              offers, and other relevant updates about Crypto Cricket. You can
              opt out of these communications at any time by following the
              unsubscribe link in our emails or by contacting us directly.
            </p>
            <p>
              <strong>Website Analytics:</strong> The usage data collected
              through Google Analytics helps us understand how visitors use our
              site, enabling us to improve our website's functionality, content,
              and overall user experience. This data is aggregated and
              anonymized, meaning it does not directly identify you as an
              individual.
            </p>
          </section>
          <section>
            <h2>3. Data Sharing</h2>
            <p>
              <strong>Third-Party Services:</strong> We do not share your email
              address with any third parties for marketing purposes. However, we
              do use Google Analytics, a third-party service provided by Google,
              Inc., which helps us analyze website traffic. Google Analytics may
              share this data with other Google services. Google may use the
              collected data to contextualize and personalize ads within its own
              advertising network. You can learn more about how Google uses data
              by visiting Google's Privacy Policy.
            </p>
          </section>
          <section>
            <h2>4. Data Security</h2>
            <p>
              We take appropriate measures to safeguard your personal
              information from unauthorized access, alteration, disclosure, or
              destruction. Our security practices include encryption, access
              controls, and regular security assessments.
            </p>
          </section>
          <section>
            <h2>5. Your Rights</h2>
            <p>
              <strong>Unsubscribe:</strong> You can unsubscribe from our
              newsletter at any time by clicking the "unsubscribe" link in any
              email you receive from us or by contacting us directly at the
              email provided below.
            </p>
            <p>
              <strong>Google Analytics Opt-Out:</strong> If you wish to opt out
              of Google Analytics tracking, you can do so by installing the{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics Opt-Out Browser Add-on
              </a>
              .
            </p>
          </section>
          <section>
            <h2>6. Cookies</h2>
            <p>
              Cookies are small text files stored on your device when you visit
              our website. We use cookies to improve your browsing experience
              and to understand how you interact with our website. You can
              control or delete cookies through your browser settings.
            </p>
          </section>
          <section>
            <h2>7. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              the use of your personal data, please contact us at:
            </p>
            <p>
              <strong>Email:</strong> info@balltokenverse.com
            </p>
          </section>
          <section>
            <h2>8. Changes to This Policy</h2>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. Any changes will be posted on this page, and
              the date of the last update will be indicated at the top.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
