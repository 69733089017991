import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import BattlesCard from "../Home Page/Battles Card/BattlesCard";
import DescriptionComp from "../../UseableComponents/Description Section/DescriptionComp";
import ProcessGuideCarousel from "../../UseableComponents/Process Guide Carousel/ProcessGuideCarousel";
import Footer from "../../Footer Section/Footer";

const DescritionPage = () => {
  const [componentToRender, setComponentToRender] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/fantasy") {
      setComponentToRender(
        <DescriptionComp
          heading={"FANTASY"}
          descImageSrc={"/assets/fantasyDesc.png"}
          content={`Fantasy Cricket involves assembling a team of real cricketers from an upcoming match, strategically choosing players based on their roles like batsmen, bowlers, wicketkeepers, and all-rounders. Selecting a Captain and Vice-Captain is pivotal, as they earn bonus points. Participants then enter their teams into contests to compete against others based on the players' actual performances in the match, aiming to score the highest points and win prizes.`}
        />
      );
    } else if (path === "/sessions") {
      setComponentToRender(
        <DescriptionComp
          heading={"SESSIONS"}
          descImageSrc={"/assets/sessionDesc.png"}
          content={`Sessions in real-time prediction games offer participants a streamlined experience with single-question contests during live matches. Each session focuses on a specific question related to the ongoing match, where participants choose from three options before a timer runs out. The excitement mounts as correct answers share the stakes from incorrect ones, making every session dynamic and engaging. This format ensures a thrilling experience, keeping participants actively involved throughout the live match.`}
        />
      );
    } else if (path === "/prediction") {
      setComponentToRender(
        <DescriptionComp
          heading={"PREDICTIONS"}
          descImageSrc={"/assets/predictionDesc.png"}
          content={`Prediction Cricket involves selecting questions related to various aspects of a cricket match, such as runs scored, boundaries, wickets, and more. Players make predictions based on these questions, with the option to double points on their most confident prediction. Success depends on the accuracy of these predictions compared to actual match outcomes. Participants can join multiple contests without restrictions, utilizing BALL tokens or other supported tokens like USDT, with plans for additional token support in the future.`}
        />
      );
    }
  }, []);

  return (
    <div>
      <Navbar />
      {componentToRender}
      <BattlesCard />
      <ProcessGuideCarousel type={window.location.pathname.replace("/", "")} />
      <Footer />
    </div>
  );
};

export default DescritionPage;
