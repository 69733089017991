import React, { useState } from "react";
import styles from "./Footer.module.css";
import { FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import axios from "axios";
import { IP, PORT } from "../../config";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorColor, setErrorColor] = useState("");

  const subscribeNewsletter = async () => {
    try {
      const resp = await axios.post(
        `${IP}:${PORT}/api/website-data/subscribe/news-letter`,
        { email: email }
      );
      setErrorColor("green");
      if (resp.status === 201) setEmailError("Thank you for Subscribing!");
      else if (resp.status === 200) setEmailError("You've already subscribed!");
      console.log(resp);
    } catch (error) {
      setErrorColor("red");
      setEmailError(error.response.data.message.replaceAll(`"`, ``));
      console.log("error", error.response.data.message);
    }
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerWrap}>
        {/* <div className={styles.footerTop}>
          <div className={styles.header}>
            <p className={styles.headerTitle}>SEND US A MAIL</p>
            <h2>
              Join Us As a Super Fans and Get all <span>the benefits !</span>
            </h2>
            <p>
              Our newsletter lets you keep pace with the latest and the best in
              the world of Cricket!
            </p>
            <div className={styles.joinTeam}>
              <button>Join Our Team</button>
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.nameEmailWrap}>
              <input type="text" placeholder="Your Name" />
              <input type="email" placeholder="Email" />
            </div>
            <div className={styles.messageBox}>
              <textarea
                name=""
                id=""
                rows={10}
                placeholder="Message"
              ></textarea>
            </div>
            <div className={styles.sendMsgBtn}>
              <button>Send Message</button>
            </div>
          </div>
        </div> */}
        <div className={styles.footerBottom}>
          <div className={styles.companyDetails}>
            <div className={styles.logo}>
              <img src="/assets/ccLogo.png" alt="" />
            </div>
            <div className={styles.companyDesc}>
              <p>
                Crypto Cricket is your key resource for making online cricket a
                rewarding experience with high ROIs.
              </p>
            </div>
            <div className={styles.socialIcons}>
              <a
                href="https://t.me/cryptocricketsupport"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaTelegramPlane size={20} />
                </i>
              </a>
              <a
                href="https://www.instagram.com/cryptocricket.io/"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaInstagram size={20} />
                </i>
              </a>
              {/* <i>
                <a href="">
                  <FaLinkedinIn size={20} />
                </a>
              </i> */}
              <a
                href="https://x.com/cryptocricketio?s=11&t=pyRJPzfIQfFni-13Dx7J0g"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FaXTwitter size={20} />
                </i>
              </a>
            </div>
          </div>
          <div className={styles.useFulLinks}>
            <h6>Useful Links</h6>
            {/* <a href="/aboutus">About Us</a> */}
            <a
              href="https://docs.balltokenverse.com/whitepaper"
              target="_blank"
              rel="noreferrer"
            >
              Whitepaper
            </a>
            <div className={styles.useFulLinks}>
              <h6>Contact Us</h6>
              <p>info@balltokenverse.com</p>
              {/* <a href="/aboutus"></a> */}
              {/* <a href="/faq">Whitepaper</a> */}
            </div>
          </div>
          <div className={styles.contact}>
            <h6>Address</h6>
            <div className={styles.location}>
              <p>House / Flat No:</p>
              <p>Plot no: 194, 3rd Floor</p>
            </div>
            <div className={styles.location}>
              <p>Street / City:</p>
              <p>Ayyappa Society, Madhapur</p>
            </div>
            <div className={styles.phone}>
              <p>PHONE:</p>
              <p>+91-6305602096</p>
            </div>
            {/* <div className={styles.joinUs}>
              <p>JOIN US:</p>
              <p>cryptocricket@gmail.com</p>
            </div> */}
          </div>
          <div className={styles.newsLetter}>
            <h6>Newsletter Signup</h6>
            <input
              type="email"
              placeholder="Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span
              style={{
                color: errorColor,
              }}
            >
              {emailError}
            </span>
            <button
              onClick={subscribeNewsletter}
              // style={{ color: !validateEmail(email) ? "grey" : "" }}
            >
              Subscribe Now
            </button>
            {/* <p>
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam{" "}
            </p> */}
          </div>
        </div>
        <div className={styles.copyright}>
          <p>© Copyright 2024 Cryptocricket All Rights Reserved.</p>
          <p>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
