import React from "react";
import styles from "./Tokenomics.module.css";

const Tokenomics = () => {
  return (
    <div className={styles.tokenomicsContainer}>
      <div className={styles.tokenomicsContent}>
        <div className={styles.header}>
          <h2>TOKENOMICS</h2>
          <p>
            Dive into the distribution, utility, and value of Crypto Cricket's
            tokens, designed to enhance player engagement and reward your
            participation in the game.
          </p>
        </div>
        <div className={styles.graph}>
          <div className={styles.donutImage}>
            <img src="/assets/donut.png" alt="" />
          </div>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <h6>Community</h6>
              <p>70 Millions</p>
              <div className={styles.bar}></div>
            </div>
            <div className={styles.stat}>
              <h6>Team</h6>
              <p>20 Millions</p>
              <div className={styles.bar}></div>
            </div>
            <div className={styles.stat}>
              <h6>Other</h6>
              <p>10 Millions</p>
              <div className={styles.bar}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
