import React from "react";
import styles from "./Teams.module.css";
import Team from "./Team Card/Team";
import Users from "./Users Card/Users";

const Teams = () => {
  return (
    <div className={styles.teamsContainer}>
      {/* <Team /> */}
      <Users />
    </div>
  );
};

export default Teams;
