import React from "react";
import styles from "./DescriptionComp.module.css";

const DescriptionComp = ({ heading, content, descImageSrc }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href =
      "https://s3.ap-south-2.amazonaws.com/production.cryptocricket/apks/cryptocricket.apk";
    link.download = "cryptocricket.apk";
    link.click();
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.matter}>
          <h1>{heading}</h1>
          <p>{content}</p>
          <button onClick={handleDownload}>Play Now</button>
        </div>
        <div className={styles.descImage}>
          <img src={descImageSrc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DescriptionComp;
