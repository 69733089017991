import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/Pages/Home Page/HomePage";
import DescritionPage from "./components/Pages/Description Page/DescritionPage";
import LeaderBoardPage from "./components/Pages/LeaderBoard Page/LeaderBoardPage";
import AboutUsPage from "./components/Pages/About Us Page/AboutUsPage";
import ContactPage from "./components/Pages/Contact Page/ContactPage";
import PrivacyPolicyPage from "./components/Pages/Privacy Policy/PrivacyPolicyPage";
import TermsAndConditionsPage from "./components/Pages/Terms And Conditions/TermsAndConditionsPage";
import DownloadExclusivePage from "./components/DownloadExclusivePage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={"/sessions"} element={<DescritionPage />} />
        <Route path="/fantasy" element={<DescritionPage />} />
        <Route path="/prediction" element={<DescritionPage />} />
        <Route path="/leaderboard" element={<LeaderBoardPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/download-exclusive" element={<DownloadExclusivePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
