import React, { useEffect, useState } from "react";
import styles from "./BattlesCard.module.css";
import BattleTeam from "../../../UseableComponents/BattleTeam/BattleTeam";
import axios from "axios";
import { IP, PORT } from "../../../../config";
import useIntersectionObserver from "../../../UseableComponents/useIntersectionObserver";

const BattlesCard = () => {
  const [matches, setMatches] = useState([]);
  const [showMatchesCount, setShowMatchesCount] = useState(3);

  const [headRef, headerVisible] = useIntersectionObserver({ threshold: 1 });

  const formatDateTime = (milliseconds) => {
    const date = new Date(milliseconds);
    const timeStr = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const dateStr = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return { timeStr, dateStr };
  };

  const getMatches = async () => {
    try {
      const response = await axios.get(
        `${IP}:${PORT}/api/website-data/matches`
      );
      console.log(response.data);
      setMatches(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMoreMatches = () => {
    setShowMatchesCount(showMatchesCount + 3);
  };

  useEffect(() => {
    getMatches();
  }, []);
  return (
    <div className={styles.battleCardWrap}>
      <div className={styles.battleContent}>
        <div
          className={`${styles.battleHeader} ${
            headerVisible ? styles.animate : ""
          }`}
          ref={headRef}
        >
          {/* <p>UPCOMING MATCHES</p> */}
          <h2>
            {/* <span>UPCOMING EXTREME</span> <br />
            <span>CRICKET BATTLES</span> */}
            UPCOMING MATCHES
          </h2>
          <p>
            Showcase your skill and determination in every clash to rise to the
            top!
          </p>
        </div>
        <div className={styles.battles}>
          {matches.map((match, matchIndex) => {
            const { timeStr, dateStr } = formatDateTime(match.startDate * 1000);
            if (matchIndex < showMatchesCount) {
              return (
                <div className={styles.battle}>
                  <BattleTeam
                    right={false}
                    teamName={match?.teamA?.name}
                    teamDetails={match?.matchNumber}
                    teamLogo={match?.teamA?.image}
                  />
                  <div className={styles.battleTime}>
                    <h1>{timeStr}</h1>
                    <p>{dateStr}</p>
                  </div>
                  <BattleTeam
                    right={true}
                    teamName={match?.teamB?.name}
                    teamDetails={match?.seriesName}
                    teamLogo={match?.teamB?.image}
                  />
                </div>
              );
            }
          })}
        </div>
        {showMatchesCount > matches.length ? (
          ""
        ) : (
          <div className={styles.loadMoreBattlesBtn}>
            <button onClick={handleLoadMoreMatches}>Load More Matches</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BattlesCard;
