import React from "react";
import styles from "./RoadMap.module.css";
import useIntersectionObserver from "../../../UseableComponents/useIntersectionObserver";

const RoadMap = () => {
  const [phaseRef, phaseVisible] = useIntersectionObserver({ threshold: 0.3 });

  return (
    <div className={styles.roadMapContainer}>
      <div className={styles.roadMapContent}>
        <div className={styles.header}>
          <h2>ROADMAP</h2>
          <p>
            The roadmap outlines current and future phases, allowing for
            adjustments to meet the community's needs.
          </p>
        </div>
        <div className={styles.roadMap}>
          <div
            className={`${styles.phase} ${styles.phase1} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>1</span>
            <h6>Prediction Cricket</h6>
            <p>
              Predict match outcomes for rewards, testing your cricket insights
              against real match results.{" "}
            </p>
          </div>
          <div
            className={`${styles.phase} ${styles.phase2} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>2</span>
            <h6>Sessions</h6>
            <p>
              Participate in real-time single-question contests during live
              matches, offering immediate engagement and excitement.
            </p>
          </div>
          <div
            className={`${styles.phase} ${styles.phase3} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>3</span>
            <h6>Fantasy Cricket</h6>
            <p>
              Build fantasy teams and compete in leagues, earning points based
              on players' real-life performances.
            </p>
          </div>
          <div
            className={`${styles.phase} ${styles.phase4} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>4</span>
            <h6>Series Staking</h6>
            <p>
              Stake tokens to earn from platform fees, providing a way to invest
              in the platform (pending token listing).
            </p>
          </div>
          <div
            className={`${styles.phase} ${styles.phase5} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>5</span>
            <h6>Weekly Tournaments</h6>
            <p>
              Join contests to earn rewards using various tokens, with new
              opportunities each week to win big.
            </p>
          </div>
          <div
            className={`${styles.phase} ${styles.phase6} ${
              phaseVisible ? styles.animate : ""
            }`}
            ref={phaseRef}
          >
            <span>6</span>
            <h6>Mini Games</h6>
            <p>
              Future multiplayer games based on cricket themes, adding a fun and
              interactive dimension to the platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
