import React from "react";
import styles from "./Users.module.css";
import { Carousel } from "react-responsive-carousel";
import { IsMobile } from "../../../../../Constants";
import "./Users.css";
import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";

const Users = ({ backgroundImage }) => {
  const isMobile = IsMobile();

  return (
    <div
      className={styles.userContainer}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.userContent}>
        <div className={styles.header}>
          {/* <h6></h6> */}
          <h2>
            {/* <span>USERS ABOUT</span> <span>CRYPTO CRICKET</span> */}
            TESTIMONIALS
          </h2>
          <p>
            Fandom of Crypto Cricket increases by the day, even as customer
            retention is close to 100%!
          </p>
        </div>
        <div className={styles.userCarousel}>
          <Carousel
            centerMode
            autoPlay={true}
            interval={2000}
            centerSlidePercentage={isMobile ? 100 : 50}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            showArrows={!isMobile}
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div className="customArrowNext" onClick={clickHandler}>
                  <HiMiniArrowLongRight size={25} />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div className="customArrowPrev" onClick={clickHandler}>
                  <HiMiniArrowLongLeft size={25} />
                </div>
              );
            }}
          >
            <div className={styles.userCarouselItem}>
              <div className={styles.userDetails}>
                {/* <div className={styles.userDp}>
                  <img src="/assets/testimonial-3.jpg.png" alt="" />
                </div> */}
                <div className={styles.userName}>
                  <h6>Rakesh</h6>
                  <p>Rakesh20</p>
                </div>
              </div>
              <div className={styles.userComment}>
                <p>
                  After seeing my friend win t-shirts and caps during IPL, I
                  installed the app and found it greatly improved and enjoyable
                  to use! 😀
                </p>{" "}
              </div>
            </div>
            <div className={styles.userCarouselItem}>
              <div className={styles.userDetails}>
                {/* <div className={styles.userDp}>
                  <img src="/assets/testimonial-3.jpg.png" alt="" />
                </div> */}
                <div className={styles.userName}>
                  <h6>Suryatejasama</h6>
                  <p>surya2516</p>
                </div>
              </div>
              <div className={styles.userComment}>
                <p>
                  I follow IPL and predict all the time. I win a lot. If you too
                  like to follow IPL, let’s match our wits at Crypto Cricket.
                </p>{" "}
              </div>
            </div>
            <div className={styles.userCarouselItem}>
              <div className={styles.userDetails}>
                {/* <div className={styles.userDp}>
                  <img src="/assets/testimonial-3.jpg.png" alt="" />
                </div> */}
                <div className={styles.userName}>
                  <h6>Yeshwanth</h6>
                  <p>yesh31</p>
                </div>
              </div>
              <div className={styles.userComment}>
                <p>
                  Discovered Crypto Cricket through a friend during IPL, spread
                  the word among college friends. Educating more students could
                  help reach more people. Good luck! 🤞🏻
                </p>{" "}
              </div>
            </div>
            <div className={styles.userCarouselItem}>
              <div className={styles.userDetails}>
                {/* <div className={styles.userDp}>
                  <img src="/assets/testimonial-3.jpg.png" alt="" />
                </div> */}
                <div className={styles.userName}>
                  <h6>Rohitkumar</h6>
                  <p>Rohit</p>
                </div>
              </div>
              <div className={styles.userComment}>
                <p>
                  From Instagram predictions to earning rewards like caps and
                  T-shirts, transitioning to ball predictions and USDT swaps on
                  the app was seamless and user-friendly.
                </p>{" "}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Users;
