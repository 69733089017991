import React from "react";
import styles from "../Privacy Policy/Policy Card/PrivacyPolicy.module.css";

const Terms = () => {
  return (
    <div className={styles.policyContainer}>
      <div className={styles.header}>
        <h1>Terms and Conditions for Crypto Cricket</h1>
      </div>
      <div className={styles.rulesContainer}>
        <div className={styles.content}>
          <section>
            <p>
              <b>Last Updated:</b> 09/08/2024
            </p>
            <p>
              Welcome to Crypto Cricket! By accessing or using the Crypto
              Cricket platform, you agree to comply with and be bound by the
              following Terms and Conditions. Please read them carefully before
              using the app.
            </p>
          </section>

          <section>
            <h2>1. Overview</h2>
            <p>
              Crypto Cricket is a platform where users can participate in
              skill-based cricket games. The platform offers both free games,
              where users can earn rewards without any deposit, and paid games,
              where users deposit, withdraw, and earn rewards in cryptocurrency.
              Crypto Cricket does not facilitate any conversion between
              cryptocurrency and fiat currencies.
            </p>
          </section>

          <section>
            <h2>2. Eligibility</h2>
            <p>
              You must be at least 18 years old to use the Crypto Cricket app.
              By creating an account, you confirm that you meet the age
              requirement and that you are legally permitted to use the app in
              your jurisdiction.
            </p>
            <p>
              It is your responsibility to ensure that participating in
              skill-based games using cryptocurrency tokens is allowed in your
              region.
            </p>
          </section>

          <section>
            <h2>3. Account Creation</h2>
            <p>
              To use the Crypto Cricket platform, you must create an account
              using a valid email address or phone number.
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account.
            </p>
            <p>
              You agree to provide accurate and up-to-date information during
              the account registration process.
            </p>
          </section>

          <section>
            <h2>4. Games and Participation</h2>
            <p>
              Crypto Cricket offers both free and paid games. In free games,
              users can earn rewards based on their positions on the leaderboard
              without making any deposit.
            </p>
            <p>
              In paid games, users must deposit cryptocurrency tokens to
              participate. All deposits, withdrawals, and rewards are handled in
              cryptocurrency only.
            </p>
            <p>
              Crypto Cricket does not guarantee that users will always win.
              Participation in games involves risk, and users should be cautious
              when investing cryptocurrency.
            </p>
          </section>

          <section>
            <h2>5. Regional Restrictions</h2>
            <p>
              It is your responsibility to ensure that the use of the Crypto
              Cricket app and participation in skill-based games using
              cryptocurrency tokens is permitted in your region.
            </p>
            <p>
              Crypto Cricket is not liable for any legal consequences resulting
              from your use of the app in a jurisdiction where such activities
              are restricted or prohibited.
            </p>
          </section>

          <section>
            <h2>6. User-Generated Content and Interaction</h2>
            <p>
              By participating in games on the Crypto Cricket platform, users
              agree to follow all rules and guidelines established by the
              platform.
            </p>
            <p>
              Crypto Cricket reserves the right to suspend or terminate accounts
              that violate these Terms and Conditions or engage in any form of
              misconduct.
            </p>
          </section>

          <section>
            <h2>7. Payments and Deposits</h2>
            <p>
              Free games do not require any payment or subscription. However, to
              participate in paid games, users must deposit cryptocurrency
              tokens into their accounts.
            </p>
            <p>
              All transactions are processed in cryptocurrency. Crypto Cricket
              does not support any conversion between cryptocurrency and fiat
              currencies.
            </p>
          </section>

          <section>
            <h2>8. Disclaimers</h2>
            <p>
              Crypto Cricket does not guarantee continuous or secure access to
              the platform. The operation of the platform may be subject to
              factors beyond our control.
            </p>
            <p>
              Participation in skill-based games involves a risk of loss. Crypto
              Cricket disclaims any responsibility for losses incurred while
              using the platform. Users should invest cautiously.
            </p>
          </section>

          <section>
            <h2>9. Support and Contact Information</h2>
            <p>
              If you have any questions, issues, or need support, you can
              contact us via email at{" "}
              <a
                href="mailto:info@balltokenverse.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@balltokenverse.com
              </a>{" "}
              or join our dedicated support group on Telegram at{" "}
              <a
                href="https://t.me/cryptocricketsupport"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://t.me/cryptocricketsupport
              </a>
              .
            </p>
          </section>

          <section>
            <h2>10. Amendments to the Terms</h2>
            <p>
              Crypto Cricket reserves the right to modify or update these Terms
              and Conditions at any time. Any changes will be posted on this
              page, and the date of the last update will be indicated at the
              top.
            </p>
            <p>
              Continued use of the app after any such changes constitutes your
              acceptance of the new Terms and Conditions.
            </p>
          </section>

          <section>
            <h2>11. Governing Law</h2>
            <p>
              These Terms and Conditions are governed by and construed in
              accordance with the laws of the jurisdiction in which Crypto
              Cricket operates, without regard to its conflict of law
              principles.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terms;
