import React from "react";
import Navbar from "../../Navbar/Navbar";
import HeroCard from "./Hero Card/HeroCard";
import BattlesCard from "./Battles Card/BattlesCard";
import Footer from "../../Footer Section/Footer";
import TournamentsCard from "./Tournaments Card/TournamentsCard";
import Teams from "./Team and Users Card/Teams";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <HeroCard />
      <TournamentsCard />
      <BattlesCard />
      <Teams />
      <Footer />
    </div>
  );
};

export default HomePage;
