import React, { useEffect, useState } from "react";
import styles from "./HeroCard.module.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { IP, PORT } from "../../../../config";
import { IsMobile } from "../../../../Constants";
import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";
import { RxCross2 } from "react-icons/rx";

const HeroCard = () => {
  const isMobile = IsMobile();

  const [banners, setBanners] = useState({
    desktopBanners: [],
    mobileBanners: [],
  });
  const [currentBanners, setCurrentBanners] = useState([]);
  const [showPanCakePopup, setShowPanCakePopup] = useState(true);

  const fetchBanners = async () => {
    try {
      const resp = await axios.get(`${IP}:${PORT}/api/website-data/banners`);
      setBanners(resp.data);
    } catch (error) {
      console.log("Error in fetching Banners", error);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href =
      "https://s3.ap-south-2.amazonaws.com/production.cryptocricket/apks/cryptocricket.apk";
    link.download = "cryptocricket.apk";
    link.click();
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setCurrentBanners(banners.mobileBanners);
    } else {
      setCurrentBanners(banners.desktopBanners);
    }
  }, [isMobile, banners]);

  return (
    <div className={styles.heroContainer}>
      {showPanCakePopup && (
        <div className={styles.panCakePopupOverlay}>
          <div className={styles.popupContent}>
            <i>
              <RxCross2
                className={styles.closeIcon}
                size={24}
                onClick={() => setShowPanCakePopup(false)}
              />
            </i>
            <a
              href="https://pancakeswap.finance/swap?inputCurrency=0x1294d35c494c5E3169df9b0Ffe03186Ba914effE&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/panCakeBanner.png" alt="" />
            </a>
          </div>
        </div>
      )}

      <div className={styles.contentWrap}>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          autoPlay={true}
          interval={2000}
          infiniteLoop
          // renderArrowPrev={(clickHandler, hasPrev) => {
          //   return (
          //     <div className="customArrowNext" onClick={clickHandler}>
          //       <HiMiniArrowLongRight size={25} />
          //     </div>
          //   );
          // }}
          // renderArrowNext={(clickHandler, hasNext) => {
          //   return (
          //     <div className="customArrowPrev" onClick={clickHandler}>
          //       <HiMiniArrowLongLeft size={25} />
          //     </div>
          //   );
          // }}
        >
          {currentBanners.map((imageSrc, imgIndex) => {
            return (
              <div className={styles.banner} key={imgIndex}>
                {/* <img src={'/assets/baner.png'} alt="" /> */}
                <img src={imageSrc} alt="" />
              </div>
            );
          })}
        </Carousel>
        <div className={styles.header}>
          <p className={styles.titlesm}>PLAY FREE GAMES</p>
          <h1>Crypto Cricket</h1>
          <p>
            Where crypto meets cricket, blending the game's excitement with the
            rise of digital currencies. A unique experience for both crypto and
            cricket enthusiasts.
          </p>
          <div className={styles.downloadBtn}>
            <button onClick={handleDownload}>Play Now</button>
          </div>
        </div>

        <div className={styles.heroOverlay}>
          <div className={styles.heroImage}>
            <img src="/assets/heroMobileImage.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
