import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "./ProcessGuideCarousel.module.css";
import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";
import { IsMobile } from "../../../Constants";
import useIntersectionObserver from "../useIntersectionObserver";

const ProcessGuideCarousel = ({ type }) => {
  const [headerRef, headerVisible] = useIntersectionObserver({ threshold: 1 });
  const isMobile = IsMobile();

  const processGuideData = {
    fantasy: [
      {
        StepImageUrl: "/assets/processGuideStep1.png",
        StepTitle: "Select Match",
        StepExplanation:
          "Choose an upcoming cricket match to begin crafting your fantasy team and making predictions for the game.",
      },
      {
        StepImageUrl: "/assets/ProcessGuideFantasyStep2.png",
        StepTitle: "Select Fantasy",
        StepExplanation:
          "Craft your dream team from upcoming matches and compete in fantasy leagues to earn rewards based on player performances.",
      },
      {
        StepImageUrl: "/assets/ProcessGuideStep3FP.png",
        StepTitle: "Select Contest",
        StepExplanation:
          "Pick players from the upcoming match to see how your cricket predictions align with the actual game outcomes.",
      },
    ],
    prediction: [
      {
        StepImageUrl: "/assets/processGuideStep1.png",
        StepTitle: "Select Match",
        StepExplanation:
          "Choose an upcoming cricket match from the list to start making your predictions and test your cricket knowledge.",
      },
      {
        StepImageUrl: "/assets/ProcessGuidePredictionStep2.png",
        StepTitle: "Select Prediction",
        StepExplanation:
          "Pick a prediction from the different game formats to see how well your cricket insights match the actual game outcomes.",
      },
      {
        StepImageUrl: "/assets/ProcessGuideStep3FP.png",
        StepTitle: "Select Contest",
        StepExplanation:
          "Choose a contest to enter and compete for rewards based on your cricket predictions and knowledge",
      },
    ],
    sessions: [
      {
        StepImageUrl: "/assets/processGuideStep1.png",
        StepTitle: "Select Match",
        StepExplanation:
          "Choose an upcoming cricket match to participate in real-time question contests and engage actively during the game. ",
      },
      {
        StepImageUrl: "/assets/ProcessGuideSessionsStep2.png",
        StepTitle: "Select Sessions",
        StepExplanation:
          "Join a live cricket match session to participate in real-time contests and engage with single-question challenges as the game unfolds.",
      },
      {
        StepImageUrl: "/assets/ProcessGuideSessionsStep3.png",
        StepTitle: "Select Question",
        StepExplanation:
          "Choose a question and test your cricket prediction accuracy to see how well you know the game.",
      },
    ],
  };

  return (
    <div className={styles.guideContainer}>
      <div className={styles.guideContent}>
        <div
          className={`${styles.header} ${headerVisible ? styles.animate : ""}`}
          ref={headerRef}
        >
          <h4>HOW TO PLAY</h4>
          <p>
            Create an account, choose a game mode, and use your cricket
            knowledge to make predictions or manage teams to win rewards.
          </p>
        </div>
        <div className={styles.guideCarousel}>
          <Carousel
            centerMode
            centerSlidePercentage={isMobile ? 100 : 33}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            autoPlay={true}
            interval={2000}
            // renderArrowPrev={(clickHandler, hasPrev) => (
            //   <div className="customArrowNext" onClick={clickHandler}>
            //     <HiMiniArrowLongRight size={25} />
            //   </div>
            // )}
            // renderArrowNext={(clickHandler, hasNext) => (
            //   <div className="customArrowPrev" onClick={clickHandler}>
            //     <HiMiniArrowLongLeft size={25} />
            //   </div>
            // )}
          >
            {processGuideData[type].map((item, index) => (
              <div className={styles.guideItem} key={index}>
                <div className={styles.processImg}>
                  <img src={item.StepImageUrl} alt="" />
                </div>
                <div className={styles.stepDetails}>
                  <p>Step {index + 1}</p>
                  <h6>{item.StepTitle}</h6>
                  <p>{item.StepExplanation}</p>
                  {/* <a href="">Learn More</a> */}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ProcessGuideCarousel;
