import React, { useEffect } from "react";

const DownloadExclusivePage = () => {
  useEffect(() => {
    window.location.href =
      "https://s3.ap-south-2.amazonaws.com/production.cryptocricket/apks/cryptocricket-exclusive.apk";
  }, []);

  return (
    <div>
      <p>Redirecting to download...</p>
      <p>
        If you are not redirected,{" "}
        <a href="https://s3.ap-south-2.amazonaws.com/production.cryptocricket/apks/cryptocricket-exclusive.apk">
          click here
        </a>
        .
      </p>
    </div>
  );
};

export default DownloadExclusivePage;
