import React from "react";
import styles from "./ContentHeroSection.module.css";

const ContentHeroSection = ({ rewards }) => {
  return (
    <div className={styles.contestHeroContainer}>
      <div className={styles.contestDetails}>
        <div className={styles.contestHeader}>
          <p>CONTEST</p>
          <h2>
            {/* <span>loream lorem</span> */}
            <span>Tournament 2024</span>
          </h2>
          <p>
            Join the Tournament 2024 to compete with other cricket enthusiasts.
            Test your skills and predictions for a chance to win exciting
            rewards.
          </p>
        </div>
        <div className={styles.rewardBanner}>
          <div className={styles.rewardTitle}>
            <div className={styles.cupSymbol}>
              <img src="/assets/worldcup.png" alt="" />
            </div>
            <h6>World Cup 2023</h6>
          </div>
          <div className={styles.price}>
            <h6>
              <img src="/assets/noto_trophy.png" alt="" />
              {rewards?.firstPrize} BALL
            </h6>
            <p>1st price</p>
          </div>
          <div className={styles.price}>
            <h6>
              <img src="/assets/noto_trophy.png" alt="" />
              {rewards?.secondPrize} BALL
            </h6>
            <p>2nd price</p>
          </div>
          <div className={styles.price}>
            <h6>
              <img src="/assets/noto_trophy.png" alt="" />
              {rewards?.thirdPrize} BALL
            </h6>
            <p>3rd price</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentHeroSection;
