import React from "react";
import styles from "./LeaderBoardTable.module.css";

const LeaderBoardTable = ({ leaderboardData }) => {
  return (
    <div className={styles.leaderBoardContainer}>
      <div className={styles.leaderBoardContent}>
        <div className={styles.header}>
          <p>CONTEST</p>
          <h4>LEADERBOARD</h4>
        </div>
        <div className={styles.leaderBoardTable}>
          <table>
            <thead>
              <th>No</th>
              <th>Name</th>
              <th>Reward</th>
              <th>Points</th>
              <th>Rank</th>
            </thead>
            <tbody>
              {leaderboardData?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <img src={item?.profileImage} alt="" />
                      {item?.user}
                    </td>
                    <td>{item?.reward?.toFixed(3)}</td>
                    <td>{item?.totalScore}</td>
                    <td>{item?.rank}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardTable;
